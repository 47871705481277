@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
    /* add additional src declarations for other font file formats */
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue-Regular.ttf') format('truetype');
    /* add additional src declarations for other font file formats */
  }